<template>
  <component :is="template"></component>
</template>
<script>

import { mapGetters } from "vuex";
import CartaList from "./cartaList";

export default {
  components: {
    CartaList,
  },
  data() {
    return {
      carta: {
        ejecutora : '',
        estado : true,
      },
    };
  },
  mounted() {
    let muta = {};

    if (this.$route.query.oper === undefined) {
      muta.oper = "list";
      muta.carta = this.carta;
    } else {
      muta.oper = this.$route.query.oper;
    }
    
    this.$store.commit("gsfianza/OPERACION_CARTA", muta);
  },

  computed: { ...mapGetters({ template: "gsfianza/getCartaTpl" }) },
};
</script>
<style>
</style>